import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Fathom from 'fathom-client';

import SolanaProvider from '../components/mint/SolanaProvider.js';
import './styles/globals.css';

const LuckyAntsColony = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    Fathom.load('OCGRRPXJ', {
      includedDomains: ['luckyantscolony.com', 'www.luckyantscolony.com'],
    });
    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <title>Lucky Ants Colony</title>
      </Head>
      <SolanaProvider>
        <Component {...pageProps} />
      </SolanaProvider>
    </>
  );
};

export default LuckyAntsColony;
