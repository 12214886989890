import { useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { getPhantomWallet } from '@solana/wallet-adapter-wallets/lib/phantom.js';
import { getSolletWallet } from '@solana/wallet-adapter-wallets/lib/sollet.js';
import { getSolflareWallet } from '@solana/wallet-adapter-wallets/lib/solflare.js';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

export default function SolanaProvider({ children, className }) {
  // Can be set to 'Devnet', 'Testnet', or 'Mainnet'
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
  // Only the wallets you configure here will be compiled into your application
  const wallets = useMemo(() => [getPhantomWallet(), getSolletWallet(), getSolflareWallet()], []);
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect={false}>
        <WalletModalProvider container="#walletModal">{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}
